<template>
  <div :class="reportListClass" class="expense-report-list-employee">
    <Banner>
      <div class="banner-content">
        <MeepIconNotes class="banner-content__icon" />
        <span class="banner-content__text">{{
            $t("menu.expense-reports")
          }}</span>
      </div>
    </Banner>
    <PageHeader
      :has-actions="hasAction"
      :has-back="showBackButton"
      :title="headerTitle"
      @back="goBack"
    >
      <div class="page-header__extra-actions">
        <div v-if="!isClient" class="actions">
          <md-button
            v-if="selectedReports.length > 0 && previewData.length === 0"
            :class="{
              'md-primary': !isJEP,
              'md-alternate': isJEP,
            }"
            class="md-raised"
            @click="
              $route.params.types === 'history'
                ? onToCloud()
                : showConfirmUsersModal('toCloud')
            "
          >
            {{ $t("expense-notes.employees.send-to-cloud") }}
          </md-button>
          <template v-if="selectedReports.length > 0">
            <!-- Only Client Can Accept Reports -->
            <md-button
              v-if="isClient"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="showConfirmUsersModal('accept')"
            >
              {{ $t("expense-notes.employees.valid") }}
            </md-button>
            <template v-if="$route.params.types !== 'history'">
              <md-button
                :class="{
                  'md-primary': !isJEP,
                  'md-alternate': isJEP,
                }"
                class="md-raised"
                @click="onPreview"
              >
                {{ previewButtonTitle }}
              </md-button>
              <md-button
                v-if="selectedReports.length === 1 && previewData.length === 0"
                :class="{
                  'md-primary': !isJEP,
                  'md-alternate': isJEP,
                }"
                class="md-raised"
                @click="onEdit"
              >
                {{ $t("expense-notes.employees.edit") }}
              </md-button>
            </template>
          </template>
          <md-button
            :class="{
              'md-primary': !isJEP,
              'md-alternate': isJEP,
            }"
            class="md-raised header-action header-action-icon"
            @click="onClickQuestion"
          >
            <MeepIconCircleQuestion class="app-icon" />
          </md-button>
        </div>

        <div v-else class="actions">
          <template
            v-if="$route.params.types !== 'notes' && selectedReports.length > 0"
          >
            <md-button
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onUnvalid"
            >
              {{ $t("expense-notes.employees.unvalid") }}
            </md-button>
          </template>
          <template
            v-if="$route.params.types === 'notes' && selectedReports.length > 0"
          >
            <md-button
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onRefuse"
            >
              {{ $t("expense-notes.employees.client-refuse") }}
            </md-button>

            <md-button
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onAccept"
            >
              {{ $t("expense-notes.employees.client-accept") }}
            </md-button>
            <md-button
              v-if="selectedReports.length === 1"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onEdit"
            >
              {{ $t("expense-notes.employees.client-edit") }}
            </md-button>
          </template>
          <template
            v-if="
              $route.params.types === 'exported' && selectedReports.length > 0
            "
          >
            <md-button
              v-if="!showPay"
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onShowPay"
            >{{ $t("expense-notes.employees.client-pay") }}
            </md-button>
          </template>
          <template
            v-if="
              $route.params.types === 'history' && selectedReports.length > 0
            "
          >
            <md-button
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="setIsPaidReportToUnPaid"
            >{{ $t("expense-notes.employees.client-undo-pay") }}
            </md-button>
          </template>
          <template
            v-if="$route.params.types === 'trash' && selectedReports.length > 0"
          >
            <md-button
              :class="{
                'md-primary': !isJEP,
                'md-alternate': isJEP,
              }"
              class="md-raised"
              @click="onUnTrash"
            >{{ $t("expense-notes.employees.untrash") }}
            </md-button>
          </template>
          <md-button
            v-if="!showPay"
            :class="{
              'md-primary': !isJEP,
              'md-alternate': isJEP,
            }"
            class="md-raised header-action header-action-icon"
            @click="onClickQuestion"
          >
            <MeepIconCircleQuestion class="app-icon" />
          </md-button>
        </div>

        <div v-if="showText" class="text" v-html="helpText"></div>
      </div>
    </PageHeader>
    <div class="expense-report-list-employee-content page-layout">
      <div v-if="previewData.length === 0 && !showPay">
        <div class="expense-report-list-employee__ultilities">
          <div class="expense-report-list-employee__nav-bar">
            <md-button to="/dashboard/expense-reports/">
              {{
                $t("expense-notes.all-client", {
                  number: companies,
                })
              }}
            </md-button>
            <div
              v-if="companyId"
              class="expense-report-list-employee__nav-bar__next"
              next
            >
              <md-icon>navigate_next</md-icon>

              <md-button v-if="this.company" @click="goBack">
                {{ `${this.company.name} (${employeeCount})` }}
              </md-button>
            </div>

            <div
              v-if="userName"
              class="expense-report-list-employee__nav-bar__next"
            >
              <md-icon>navigate_next</md-icon>

              <md-button class="md-note-active">
                {{ userName }}
              </md-button>
            </div>
          </div>

          <div class="expense-report-list-employee__tabs">
            <div class="expense-report-list-employee__tabs-select">
              <md-tabs
                class="expense-report-list-employee__type"
                md-sync-route
                @md-changed="item => debouncedOnSelectChange(item, 'type')"
              >
                <md-tab
                  id="notes"
                  :md-label="$t('expense-notes.employees.tab-notes')"
                  :to="'notes'"
                />
                <md-tab
                  v-if="isClient"
                  id="exported"
                  :md-label="$t('expense-notes.employees.tab-exported')"
                  :to="'exported'"
                />
                <md-tab
                  id="history"
                  :md-label="$t('expense-notes.employees.tab-history')"
                  :to="'history'"
                />
                <md-tab
                  v-if="isClient"
                  id="trash"
                  :md-label="$t('expense-notes.employees.tab-trash')"
                  :to="'trash'"
                />
              </md-tabs>

              <md-field class="expense-report-list-employee__year">
                <md-select
                  id="year"
                  v-model="selectYear"
                  name="year"
                  placeholder="year"
                  @md-selected="item => debouncedOnSelectChange(item, 'year')"
                >
                  <md-option
                    v-for="(year, index) in arrayYears"
                    :key="index"
                    :value="year"
                  >{{ year }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <md-tabs
              :md-active-tab="selectMonth.toString()"
              class="expense-report-list-employee__type-month"
              @md-changed="item => debouncedOnSelectChange(item, 'month')"
            >
              <template slot="md-tab" slot-scope="{ tab }">
                {{ tab.label }}
                <div v-if="monthsItem[tab.label]" class="notes-red-dot" />
              </template>
              <md-tab
                v-for="(month, index) in arrayMonths"
                :id="month.toString()"
                :key="index"
                :md-label="$$filters.formatFullMonth(month)"
              />
            </md-tabs>
          </div>

          <div v-if="userStats" class="expense-report-list-employee__total">
            <p>{{ $t("expense-notes.employees.ttc") }}</p>
            <p
              v-if="userStats.totalMonthTVC"
              class="expense-report-list-employee__total-number"
            >
              {{ `${$$filters.formatNumber(userStats.totalMonthTVC)}€` }}
            </p>
          </div>
        </div>

        <LoadingCard v-if="isLoading" />

        <sortable-list
          v-if="showListReports"
          :hasPagination="true"
          :items="userReports"
          :not-found-label="$t('not-found-label')"
          :page-size="4"
          :parameters="reportParameters"
          :selectable="'multiple'"
          default-sort="date"
          @selected="onSelect"
          @item-click="onItemClick"
        />
      </div>
      <div v-else id="xlsx-table" class="xlsx-table" />

      <ExpenseReportPay
        v-if="showPay"
        :reports="selectedReports"
        type="employee"
        @back="deleteSelectedReport"
      />

      <edit-report-modal
        v-if="showEditModal"
        :expense-report="selectedReport"
        :title="$t('expense-notes.modìy-modal.title')"
        @close="showEditModal = false"
        @saved="onEditFormSubmitted"
      />

      <confirm-action-modal
        v-if="isConfirmModalOpen"
        :objectToActUpon="selectedReports"
        :text="confirmModalText"
        @close="closeConfirmModal"
        @confirm="handleConfirm"
      >
        <template #icon>
          <MeepIconDocMoney class="app-icon-custorm-size" />
        </template>
      </confirm-action-modal>
    </div>
  </div>
</template>

<script>
import companiesModel from "../../model/companies";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import Banner from "@/components/Banner";
import MeepIconNotes from "@/components/icons/MeepIconNotes.vue";
import MeepIconCircleQuestion from "@/components/icons/MeepIconCircleQuestion.vue";
import MeepIconDocMoney from "@/components/icons/MeepIconDocMoney.vue";

import reportModel from "@/model/expense-reports";
import expenseReports from "@/model/expense-reports";
import EditReportModal from "./modal/edit-report-modal";
import confirmActionModal from "@/components/modal/confirm-action";
import { mapGetters } from "vuex";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { adaptReport, adaptUser } from "@/services/util";
import ExpenseReportPay from "./expense-report-pay";
import getMonth from "date-fns/fp/getMonth";
import canvasDatagrid from "canvas-datagrid";
import users from "@/model/users";
import files from "@/model/files";
import { FOLDERS } from "@/constants";
import { getYear } from "date-fns/esm";

export default {
  name: "ExpenseReportListEmployees",

  components: {
    LoadingCard,
    PageHeader,
    EditReportModal,
    ExpenseReportPay,
    MeepIconNotes,
    MeepIconCircleQuestion,
    MeepIconDocMoney,
    Banner,
    confirmActionModal,
  },

  data() {
    return {
      companies: null,
      company: null,
      companyId: null,
      authorId: null,
      isLoading: false,
      userReports: [],
      selectedReports: [],
      employeeCount: "",
      showText: false,
      arrayYears: [],
      selectMonth: getMonth(new Date()),
      arrayMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
      showPay: false,
      showEditModal: false,
      userStats: null,
      userName: "",
      selectYear: getYear(new Date()),
      ids: [],
      previewData: [],
      linkToNoteFolder: "",
      debouncedOnSelectChange: debounce(this.onChangeSelect, 100),
      isConfirmModalOpen: false,
      confirmModalContext: "",
      monthsItem: {
        août: 0,
        avril: 0,
        décembre: 0,
        février: 0,
        janvier: 0,
        juillet: 0,
        juin: 0,
        mai: 0,
        mars: 0,
        novembre: 0,
        octobre: 0,
        septembre: 0,
      },
    };
  },

  computed: {
    ...mapGetters(["isCollab", "isClient", "isJEP", "isGC"]),
    reportListClass() {
      return {
        "report-list-employee-gc": this.isGC,
      };
    },
    reportParameters() {
      const params = [
        {
          name: this.$t("expense-notes.employees.table-date"),
          key: "date",
          format: this.$$filters.formatDate,
        },
        {
          name: this.$t("expense-notes.employees.table-title"),
          key: "title",
          width: "20%",
        },
        {
          name: this.$t("expense-notes.employees.table-category"),
          key: "accountingAccount.name",
          width: "18%",
        },
        {
          name: this.$t("expense-notes.employees.table-amount"),
          key: "amount",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.employees.table-tva"),
          key: "tva",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.employees.table-total"),
          key: "total",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.employees.table-paid"),
          key: "isPaid",
          format: this.$$filters.formatPaid,
        },
        {
          name: this.$t("expense-notes.employees.table-attachment"),
          isIcon: true,
          iconFormat: item => {
            return item.fileId ? "attach_file" : "";
          },
          iconClass: () => "",
          clickable: true,
        },
      ];

      const historyParams = [
        {
          name: this.$t("expense-notes.employees.table-date"),
          key: "date",
          format: this.$$filters.formatDate,
        },
        {
          name: this.$t("expense-notes.employees.table-title"),
          key: "title",
        },
        {
          name: this.$t("expense-notes.employees.table-category"),
          key: "accountingAccount.name",
        },
        {
          name: this.$t("expense-notes.employees.table-amount"),
          key: "amount",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.employees.table-tva"),
          key: "tva",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.employees.table-total"),
          key: "total",
          format: this.$$filters.formatNumber,
        },
        {
          name: this.$t("expense-notes.employees.table-paid-date"),
          key: "paidDate",
          format: this.$$filters.formatDate,
        },
        {
          name: this.$t("expense-notes.pay.payment-method"),
          key: "paymentMethod",
        },
        {
          name: this.$t("expense-notes.employees.table-attachment"),
          isIcon: true,
          iconFormat: item => {
            return item.fileId ? "attach_file" : "";
          },
          iconClass: () => "",
          clickable: true,
        },
      ];

      return this.$route.params.types === "history" ? historyParams : params;
    },

    confirmModalText() {
      if (this.confirmModalContext === "exportPreview") {
        return {
          header: "Déposer dans le cloud",
          body() {
            return "La note de frais que vous êtes en train de prévisualiser va être envoyée dans le cloud.";
          },
          question: "Êtes vous sûr de vouloir continuer ?",
        };
      } else
        return {
          header: "Déposer dans le cloud",
          body() {
            return "";
          },
          question: "Êtes vous sûr de vouloir continuer ?",
        };
    },

    showBackButton() {
      return Boolean(this.$route.params.companyId);
    },

    hasAction() {
      return this.canAcceptRefuse || this.canEdit || this.canExport;
    },

    selectedReport() {
      return get(this.selectedReports, "[0]", null);
    },

    isMultipleFileSelected() {
      return (
        Array.isArray(this.selectedReports) && this.selectedReports.length > 1
      );
    },

    isSingleFileSelected() {
      return (
        Array.isArray(this.selectedReports) && this.selectedReports.length === 1
      );
    },

    showListReports() {
      return !this.isLoading;
    },

    canExport() {
      return (
        this.isCollab &&
        (this.isSingleFileSelected || this.isMultipleFileSelected)
      );
    },

    canAcceptRefuse() {
      return true;
    },

    canEdit() {
      return true;
    },

    headerTitle() {
      return this.userName;
    },

    helpText() {
      if (this.isClient) {
        switch (this.$route.params.types) {
          case "notes":
            return this.$t("expense-notes.employees.client-notes-text");
          case "history":
            return this.$t("expense-notes.employees.client-history-text");
          default:
            return this.$t("expense-notes.employees.client-exported-text");
        }
      } else {
        if (this.$route.params.types === "notes") {
          if (this.previewData.length > 0) {
            return this.$t("expense-notes.employees.preview-text");
          }
          return this.$t("expense-notes.employees.question-sub-text");
        }
        return this.$t("expense-notes.employees.question-history-text");
      }
    },

    previewButtonTitle() {
      if (this.previewData.length > 0) {
        return this.$t("expense-notes.employees.export");
      }
      return this.$t("expense-notes.employees.preview");
    },
  },

  watch: {
    $route: "loadExportData",
  },

  async mounted() {
    await this.loadMetaData();
    await this.loadExportData();

    const user = await users.get(this.$route.params.id);
    this.userName = adaptUser(user).fullName;

    for (let i = this.selectYear - 10; i < this.selectYear + 10; i++) {
      this.arrayYears.push(i);
    }
  },

  methods: {
    goBack() {
      if (this.previewData.length !== 0) {
        this.previewData = [];
        this.selectedReports = [];
      } else if (this.showPay) {
        this.selectedReports = [];
        this.showPay = false;
      } else {
        window.history.back();
      }
    },

    async onExport() {
      try {
        await reportModel.exportReports(this.ids);

        this.$toasted.global.AppSucces({
          message: this.$t("expense-notes.employees.export-success"),
        });

        // Remove exported reports
        this.userReports = this.userReports.filter(
          report => !this.ids.includes(report.id),
        );
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });

        this.loadExportData();
      }
    },

    async onToCloud() {
      try {
        if (this.$route.params.types !== "history") {
          await reportModel.exportReports(this.ids);
          this.$toasted.global.AppSucces({
            message: this.$t("expense-notes.employees.export-success"),
          });
        }

        this.$router.push(this.linkToNoteFolder);
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      }
    },

    async onAccept() {
      try {
        const ids = this.selectedReports.map(report => report.id);
        await reportModel.accept(ids);
        this.$route.params.types = "exported";

        this.$toasted.global.AppSucces({
          message: this.$t("expense-notes.employees.valid-success"),
        });

        // Remove exported reports
        this.userReports = this.userReports.filter(
          report => !ids.includes(report.id),
        );
        this.selectedReports = [];
        this.$route.params.types = "exported";
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: this.$t("expense-notes.employees.action-fail"),
        });
      }
    },

    async onRefuse() {
      try {
        const ids = this.selectedReports.map(report => report.id);
        await reportModel.refuse(ids);
        if (this.selectedReports.length > 1) {
          this.$toasted.global.AppSucces({
            message: this.$t("expense-notes.employees.refuse-plural-success"),
          });
        } else {
          this.$toasted.global.AppSucces({
            message: this.$t("expense-notes.employees.refuse-single-success"),
          });
        }

        // Remove exported reports
        this.selectedReports = [];
        this.userReports = this.userReports.filter(
          report => !ids.includes(report.id),
        );
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: this.$t("expense-notes.employees.action-fail"),
        });
      }
    },

    async onUnTrash() {
      try {
        const ids = this.selectedReports.map(report => report.id);
        await reportModel.unTrash(ids);

        this.$toasted.global.AppSucces({
          message: this.$t("expense-notes.employees.action-success"),
        });

        // Remove exported reports
        this.selectedReports = [];
        this.userReports = this.userReports.filter(
          report => !ids.includes(report.id),
        );
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: this.$t("expense-notes.employees.action-fail"),
        });
      }
    },

    async onUnvalid() {
      try {
        const ids = this.selectedReports.map(report => report.id);
        await reportModel.unTrash(ids);

        this.$toasted.global.AppSucces({
          message: this.$t("expense-notes.employees.unvalid-success"),
        });

        // Remove exported reports
        this.selectedReports = [];
        this.userReports = this.userReports.filter(
          report => !ids.includes(report.id),
        );
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: this.$t("expense-notes.employees.action-fail"),
        });
      }
    },

    onEdit() {
      this.showEditModal = true;
    },

    async loadMetaData() {
      try {
        this.isLoading = true;
        this.companyId = this.$route.params.companyId;
        this.authorId = this.$route.params.id;
        const companies = await companiesModel.getAll();
        this.companies = companies.length;
        this.company = await companiesModel.get(this.companyId);

        const date = new Date();
        date.setFullYear(this.selectYear);
        date.setMonth(this.selectMonth);

        this.linkToNoteFolder = await files.getLinkToNoteFolder({
          createdAt: date.toISOString(),
          folderName: FOLDERS.NOTES,
          companyId: this.companyId,
        });
      } catch (err) {
        console.log(err);
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },

    async loadExportData() {
      this.isLoading = true;
      const date = new Date();
      date.setFullYear(this.selectYear);
      date.setMonth(this.selectMonth);
      this.selectedReports = [];

      const { userCount, items } = await reportModel.getExpenseReportStats({
        companyId: this.companyId,
        authorId: this.authorId,
        createdAt: date.toISOString(),
        type: this.$route.params.types,
      });

      this.linkToNoteFolder = await files.getLinkToNoteFolder({
        createdAt: date.toISOString(),
        folderName: FOLDERS.NOTES,
        companyId: this.companyId,
      });

      this.monthsItem = await expenseReports.getExpenseReportDataYears({
        companyId: this.companyId,
        authorId: this.authorId,
        createdAt: date.toISOString(),
        type: this.$route.params.types,
      });

      this.userStats = items[0];
      this.employeeCount = userCount;

      this.userReports = this.userStats.reports.map(adaptReport);
      this.isLoading = false;
    },

    onSelect(reports) {
      this.selectedReports = [...reports];
      this.ids = reports.map(item => item.id);
      if (reports.length === 0) {
        this.selectedReports = [];
      }
    },

    async onEditFormSubmitted(newData) {
      let newReport = await reportModel.updateReport(newData);
      const index = this.userReports.findIndex(
        item => item.id === newReport.id,
      );

      newReport = adaptReport(newReport);

      // Replace with new content
      this.userReports.splice(index, 1, newReport);

      this.$toasted.global.AppSucces({
        message: this.$t("expense-notes.employees.update"),
      });
    },

    onClickQuestion() {
      this.showText = !this.showText;
    },

    onChangeSelect(item, type) {
      let hasChange = false;

      if (type === "month" && this.selectMonth !== item) {
        hasChange = true;
        this.selectMonth = item;
      }

      if (type === "year") {
        hasChange = true;
        this.selectYear = item;
      }

      if (hasChange) {
        this.loadExportData();
      }
    },

    onItemClick({ item }) {
      this.$router.push("/dashboard/cloud/" + item.fileId);
    },

    async onPreview() {
      if (this.previewData.length !== 0) {
        this.showConfirmUsersModal("exportPreview");
      } else {
        this.previewData = await expenseReports.getExcelData(this.ids);

        await this.$nextTick();

        const grid = canvasDatagrid({
          parentNode: document.getElementById("xlsx-table"),
          data: this.previewData,
        });

        grid.style.width = "100%";
        grid.style.height = "calc(100vh - 350px)";
        grid.style.marginTop = "80px";
        grid.style.borderLeft = "1px solid #CCCCCC";
        grid.style.borderBottom = "1px solid #CCCCCC";
      }
    },

    onShowPay() {
      this.showPay = true;
    },

    deleteSelectedReport() {
      this.showPay = false;
      this.loadExportData();
    },

    async setIsPaidReportToUnPaid() {
      this.isLoading = true;
      try {
        await expenseReports.payReport({
          ids: this.ids,
          unPaid: true,
          paidDate: "",
          paymentMethod: "",
        });

        this.$toasted.global.AppSucces({
          message: this.$t("expense-notes.employees.unpay-success"),
        });

        this.userReports = this.userReports.filter(
          report => !this.ids.includes(report.id),
        );
        this.isLoading = false;
      } catch (error) {
        console.log(error);
        this.$toasted.global.AppError({
          message: this.$t("expense-notes.employees.action-fail"),
        });
        this.isLoading = false;
      }
    },

    showConfirmUsersModal(text) {
      this.isConfirmModalOpen = true;
      this.confirmModalContext = text;
    },

    closeConfirmModal() {
      this.isConfirmModalOpen = false;
    },

    async handleConfirm() {
      switch (this.confirmModalContext) {
        case "accept":
          this.onAccept();
          break;
        case "toCloud":
          this.onToCloud();
          break;
        case "exportPreview":
          await this.onExport();
          this.goBack();
          break;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/_variable.scss";

#app {
  .md-theme-default {
    .expense-report-list-employee {
      position: relative;

      .page-header {
        position: relative;

        &__title {
          margin: 0;
        }

        &__back-button {
          display: none;
        }

        &__extra-actions {
          position: absolute;
          right: 22px;
          top: 5px;
          @include for-lg {
            top: -5px;
            right: 50px;
          }

          .actions {
            display: flex;
            justify-content: flex-end;
            gap: toRem(8);

            .md-button {
              height: var(--header-icon-size-lg);
              margin: 0;
              @include for-lg {
                height: var(--header-icon-size-xl);
              }

              .md-ripple {
                padding: 0 1rem;
                font-size: toRem(9);
                @include for-lg {
                  font-size: toRem(14);
                }
              }
            }

            .header-action-icon .md-ripple {
              padding: 0;
            }
          }

          .text {
            height: 75px;
            width: 500px;
            line-height: 1.2;
            text-align: right;
            margin-top: 10px;
            font-size: toRem(10);
            @include for-lg {
              font-size: toRem(12);
              width: 740px;
            }
          }
        }
      }

      &__card {
        margin: 32px auto;
        padding: 16px;
        font-weight: bold;
      }

      &__ultilities {
        position: relative;
        margin-bottom: toRem(30);
      }

      &__nav-bar {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        .md-button {
          height: 44px;
          background-color: transparent;
          font-family: var(--font-bold);
          text-transform: none;
          margin: 0;
          font-size: toRem(17);
          @include for-lg {
            font-size: toRem(20);
          }

          &.md-note-active {
            color: var(--text-secondary);
          }
        }

        &__next {
          display: flex;
        }
      }

      &__tabs {
        .md-tabs {
          flex-direction: row;
        }

        .md-tabs.md-theme-default .md-tabs-navigation .md-button {
          max-width: 100%;
          font-family: var(--font-bold);
          color: #000;
          font-size: toRem(14);

          .md-ripple {
            padding: 0 1rem;
          }

          @include for-lg {
            height: 62px;
            font-size: toRem(16);
          }

          &.md-active {
            color: var(--bg-primary);
          }

          .md-button-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 5px;

            .notes-red-dot {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background: var(--primary);
            }
          }
        }

        &-select {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        }
      }

      &__year {
        width: 70px;
        position: static;
        font-family: var(--font-extrabold);
        color: #000;
        padding: 0;
        margin: 0;
        align-items: center;

        .md-icon.md-theme-default.md-icon-image svg {
          fill: #000;
        }

        .md-select {
          background-color: transparent;

          .md-input {
            font-size: toRem(18);
            @include for-lg {
              font-size: toRem(20);
            }
          }
        }
      }

      .md-field:after,
      .md-field:before {
        display: none;
      }

      &__type-month.md-tabs {
        width: 80%;
        @include for-lg{
          width: 100%;
        }
        .md-tabs-navigation {
          width: 100%;
          gap: toRem(8);
          @include for-lg {
            display: flex;
            flex-wrap: wrap;
            width: 85%;
            gap: toRem(19);
          }

          .md-button.md-tab-nav-button {
            text-transform: capitalize;
            margin: 0;
            min-width: unset;

            .md-ripple {
              padding: 0 1rem;
            }
          }
        }
      }

      &__total {
        position: absolute;
        right: 0;
        bottom: 10px;
        display: flex;
        justify-content: flex-end;

        p {
          font-size: toRem(13);
          margin: 0;
          letter-spacing: -0.5px;
          font-family: var(--font-bold);
          @include for-lg {
            font-size: toRem(20);
          }
        }

        &-number {
          padding-left: 10px;
          font-size: 20px;
          font-family: var(--font-extrabold) !important;
          color: #000;
        }
      }

      &__companies {
        .md-toolbar {
          font-family: var(--font-bold);
          box-shadow: none;
          height: 100px;
          margin: 20px 0;
          border-radius: 7px;
          padding: 0;

          .md-button {
            width: 100%;
            height: 100%;

            .md-ripple {
              padding: 0;

              .md-button-content {
                width: 100%;
                display: flex;
                align-items: center;
              }

              .md-tittle {
                margin: 0;
              }
            }

            &:hover {
              border-radius: 7px;
            }
          }
        }
      }

      .md-table-head {
        .md-table-head-container {
          height: 56px;
          display: flex;
          align-items: center;
          @include for-lg {
            height: 80px;
          }

          .md-table-head-label {
            width: 100%;
            overflow: visible;
            text-overflow: clip;
            white-space: normal;
            display: flex;
            align-items: center;
          }
        }

        &.md-table-cell-selection {
          .md-table-head-container {
            width: 66px;
          }
        }
      }

      &__red-circle {
        width: 40px;
        height: 40px;
        margin: 0 30px 0 30px;
        background-color: var(--bg-primary);
        border-radius: 50%;
      }

      .xlsx-table {
        margin-top: 4rem;
        @include for-lg {
          margin-top: 5.5rem;
        }
      }

      &-content {
        height: 100%;

        .sortable-meep {
          margin-top: toRem(15);
          @include for-lg {
            margin-top: toRem(30);
          }
        }
      }

    }
  }
}

.report-list-employee-gc {
  .expense-report-list-employee__nav-bar__next {
    .md-note-active {
      color: var(--primary) !important;
    }
  }
}
</style>
