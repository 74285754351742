import fetch from "../services/fetch";
import store from "../store";

export default {
    getAll,
};

function getAll(params) {
    return fetch("api/accounting-accounts", {
        method  : "get",
        headers : { Authorization : store.state.token },
        params  : params,
    });
}
