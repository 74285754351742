<!-- SelectItemsModal -->
<template>
  <modal class="edit-report-modal" @close="$emit('close')">
    <!-- Title -->
    <div class="modal-header">
      <div
        :class="isGC ? 'app-logo-minified' : 'app-logo'"
        v-html="isGC ? logoMinified : logo"
      />
    </div>

    <!-- Body -->
    <div class="modal-body">
      <div class="modal-body-title">
        <slot name="icon">
          <MeepIconNotes class="app-icon" />
        </slot>
        {{ title }}
      </div>
      <div class="modal-body-content meep-form">
        <form id="edit-report-modal" class="meep-form-column" @submit="onSave">
          <div class="meep-input">
            <md-field>
              <label for="title">
                Intitulé
              </label>

              <md-input
                id="title"
                v-model="model.title"
                name="title"
                type="text"
              >
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label for="fullName">
                Nom de l'auteur
              </label>

              <md-input
                id="fullName"
                v-model="model.authorName"
                disabled
                name="fullName"
                readonly
                type="text"
              >
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label for="company">
                Société
              </label>

              <md-input
                id="company"
                v-model="model.company.name"
                disabled
                name="company"
                readonly
                type="text"
              >
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label for="amount">
                Montant
              </label>

              <md-input
                id="amount"
                v-model="model.amount"
                name="amount"
                type="number"
              >
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label for="tva">
                TVA
              </label>

              <md-input id="tva" v-model="model.tva" name="tva" type="number">
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label for="ttc">
                TTC
              </label>

              <md-input id="ttc" :value="ttc" disabled name="ttc" type="number">
              </md-input>
            </md-field>
          </div>

          <div class="meep-input">
            <md-field>
              <label for="accountingAccount"> Compte </label>

              <md-select
                id="accountingAccount"
                v-model="model.accountingAccountId"
                name="accountingAccount"
              >
                <md-option
                  v-for="(accountingAccount, index) in accountingAccounts"
                  :key="index"
                  :value="accountingAccount.id"
                >
                  {{ accountingAccount.name }}
                </md-option>
              </md-select>
            </md-field>
          </div>
        </form>
      </div>
    </div>

    <div class="modal-footer">
      <md-button class="md-primary--inverted" @click.native="$emit('close')">
        Annuler
      </md-button>

      <md-button class="md-raised md-primary" @click.native="onSave">
        Valider
      </md-button>
    </div>
  </modal>
</template>

<script>
import { cloneDeep } from "lodash";
import accountingAccountModels from "../../../model/accounting-accounts";
import MeepIconNotes from "@/components/icons/MeepIconNotes.vue";
import { mapGetters } from "vuex";

export default {
  name: "EditReportModal",

  components: {
    MeepIconNotes,
  },

  props: {
    expenseReport: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
    },
  },

  data() {
    return {
      // Copy Data to avoid mutating direct object
      accountingAccounts: [],
      model: cloneDeep(this.expenseReport),
    };
  },
  computed: {
    ...mapGetters(["logo", "logoMinified", "isGC"]),

    ttc() {
      return Number(this.model.amount) + Number(this.model.tva);
    },
  },

  async mounted() {
    this.accountingAccounts = await accountingAccountModels.getAll();
  },

  methods: {
    onSave() {
      this.$emit("saved", this.model);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "../../../styles/_variable.scss";

#app {
  .edit-report-modal {
    .modal-container {
      width: 638px;
      height: 583px;
      padding: toRem(24);
      border-radius: 27px;
      @include for-lg {
        width: 965px;
        height: 875px;
        padding: toRem(36) toRem(38);
      }
    }

    .modal-header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .app-logo {
        svg {
          width: 69px;
          height: 21px;
          @include for-lg{
            width: 105px;
            height: 32px;
          }
        }
      }
    }

    .modal-body {
      padding: 0 20px;

      p {
        margin: 5px 0;
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      &-title {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: var(--modal-icon-size-lg);
        font-family: var(--font-bold);
        margin-bottom: toRem(15);
        @include for-lg {
          font-size: var(--modal-icon-size-xl);
          margin-bottom: toRem(30);
        }

        .app-icon {
          width: 40px;
          height: 40px;
          margin-right: 15px;
          @include for-lg {
            width: 50px;
            height: 50px;
          }

          path {
            fill: var(--bg-primary, #000) !important;
          }
        }
      }

      &-content.meep-form {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        @include for-lg {
          width: 670px;
        }

        .meep-form-column {
          width: 90%;
          @include for-lg{
            width: 100%;
          }
        }
      }

      .meep-input {
        background: #f2f5f7;

        .md-input {
          background: transparent;
        }

        .md-field {
          margin-bottom: 15px;

          &.md-has-value,
          &.md-focused {
            label {
            }
          }

          .md-select {
            align-items: center;

            .md-icon {
              margin: 2px;
            }
          }
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      .md-ripple{
        font-size: toRem(9);
      }
      @include for-lg {
        .md-ripple{
          font-size: toRem(14);
        }
        margin-top: toRem(40);
      }
    }

    .modal-list {
      max-height: 420px;
      overflow-y: scroll;
    }
  }
}
</style>
